import { isNonEmptyString } from '@sortlist-frontend/utils';
import { Fragment } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { getUrlHelper } from '_components/layout/PrimaryNav/DesktopNav/utils';
import { Levels } from '_core/utils/public-links/_types';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';

import { createSortedLvlAfterPosition } from '../utils';

type LinkListProps = {
  selectedLink?: string;
  allLinks: Levels;
  seeMore?: string;
  origin: string;
  locale: string;
};

export const LinkList = (props: LinkListProps) => {
  const { allLinks, selectedLink, seeMore, origin, locale } = props;
  /* The selectedAnchors test below is necessary as long as we have two types of payload returned by Olivier for the links. Once we only have the good one, use only the allLinks[selectedLink]['undefined']['undefined'] identifier */
  const firstLink = Object.keys(createSortedLvlAfterPosition(allLinks)[0])[0];
  let linkKey = isNonEmptyString(selectedLink) ? selectedLink : firstLink;
  if (allLinks[linkKey] == null) {
    // edge case when switching pages with next link and state is not updated.
    linkKey = firstLink;
  }

  const selectedAnchors =
    allLinks[linkKey]['undefined'] != null
      ? allLinks[linkKey]['undefined']['undefined']
      : allLinks[linkKey]['null']['null'] ?? [];
  const domainInfo = DomainInfo.getFromOrigin(origin);

  const getUrl = getUrlHelper(domainInfo, locale);

  return (
    <Fragment>
      {selectedAnchors.length > 0 && (
        <ul
          style={{ columns: 2 }}
          //isXs={media.xs}
          className="list-reset">
          {selectedAnchors
            .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
            .map((val, index: number) => {
              const localizedLink = getLocalizedUrl(val);
              return (
                <li
                  className="px-32 py-xs-8 py-4"
                  key={`${val.anchor}-${index}`}
                  data-testid={`skill-link-${val.link.replace(/\//g, '')}`}>
                  <a href={localizedLink} className="text-secondary-900 small top-links-anchor">
                    {val.anchor}
                  </a>
                </li>
              );
            })}
          {seeMore && (
            <li key="see-more-skills" className="px-32 py-xs-8 py-4">
              <a href={getUrl('client.explore')} className="small text-secondary-900 text-underline top-links-anchor">
                {seeMore}
              </a>
            </li>
          )}
        </ul>
      )}
    </Fragment>
  );
};
