import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useWindowMessage, WindowMessageParams } from '@sortlist-frontend/utils';
import { useMemo, useState } from 'react';

import { GetAgencyPortfolio } from '_backend/queries/get-agency-portfolio/get-agency-portfolio.types';
import { Agency, WorkIncludedAttributes } from '_types/public-api';

import { WorkDetailModal } from '.';

type Action = 'open-work-dialog' | 'close-work-dialog';
type Data = { workSlug: string; agencyId: string; currentIndex: number };

export const useWorkMessage = (params?: WindowMessageParams<Action, Data>) => useWindowMessage<Action, Data>(params);

type Props = {
  works?: WorkIncludedAttributes[];
  agencies?: Agency[];
};
const missingAgencyLogo = ImageSourceHelper.fromPath('/_img/default/default-image-square.svg');

export const WorkDetailModalEmbed = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState<WorkIncludedAttributes | null>(null);
  const [selectedAgency, setSelectedAgency] = useState<Agency | null>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { works, agencies } = props;

  const workSlugs = works?.map((work) => work.attributes.slug);

  const { sendMessageToParent } = useWorkMessage({
    onReceiveMessage: (action, data) => {
      if (action === 'open-work-dialog') {
        setSelectedAgency(agencies?.find((agency) => agency.id === data?.agencyId) ?? null);
        setSelectedWork(works?.find((work) => work.attributes.slug === data?.workSlug) ?? null);
        setCurrentIndex(data?.currentIndex ?? 0);
        setOpen(true);
      }
      if (action === 'close-work-dialog') {
        setSelectedAgency(null);
        setSelectedWork(null);
        setCurrentIndex(-1);
        setOpen(false);
      }
    },
    sendToIframeId: 'directory-actions',
  });
  /**
   * TODO -> retrieve this data from the new public api?
   * THIS is to mimic what we have in the:
   * WorkDetailModal component which is used in agency profile */
  const transformedWorksForModal: GetAgencyPortfolio['works'] | undefined = useMemo(() => {
    if (selectedAgency == null) return;

    return works?.map((work) => {
      return {
        slug: work.attributes.slug,
        agency_slug: selectedAgency.attributes.slug,
        agency_id: selectedAgency.attributes.external_id as unknown as number,
        name: work.attributes.name,
        description: work.attributes.description,
        attachment: {
          url: work.attributes.illustration_url || '',
          type: null,
        },
        /* TODO . what we do with this data ? */
        // expertises: null,
        market_type: null,
        market_size: null,
        position: null,
        budget: null,
        company_id: null,
        company_name: null,
        created_at: '',
        sector_name: null,
        start_date: null,
        end_date: null,
      };
    });
  }, [works, selectedAgency]);

  return open && selectedAgency != null && selectedWork != null ? (
    <WorkDetailModal
      agencyName={selectedAgency.attributes.name}
      agencySlug={selectedAgency.attributes.slug}
      agencyBrandingPlan={''} // @Todo: update when data available in the api: https://github.com/sortlist/public-api/issues/357
      agencyLogoUrl={selectedAgency.attributes.logo ?? missingAgencyLogo}
      onClose={() => {
        sendMessageToParent('close-work-dialog');
        setOpen(false);
      }}
      workSlug={selectedWork.attributes.slug}
      workSlugs={workSlugs}
      works={transformedWorksForModal}
      longtailsPage={true} // @TODO -> this should be removed/refactored
      index={currentIndex}
      disableCache={false}
      open
    />
  ) : null;
};
