import { Monitor } from '@sortlist-frontend/mlm';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import {
  assertNonEmptyString,
  assertNonEmptyStringOrNull,
  HttpBadRequest,
  HttpGoneError,
  HttpInternalServerError,
  HttpNotFound,
  isNonEmptyArray,
  isNonEmptyPlainObject,
} from '@sortlist-frontend/utils';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { getCookie } from 'cookies-next';
/* eslint-disable sonarjs/cognitive-complexity */
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { config } from 'src/middleware';

import { DomainInfo } from '_backend/integration/domain-info';
import { PageHead } from '_components/base/head';
import { Public404Page } from '_components/error/public-404-page';
import { LayoutPagesRouterLight } from '_components/layout/LayoutPagesRouterLight';
import { GenericPageSeo } from '_components/seo/generic-page-seo';
import { cacheConfig } from '_config/cache.config';
import { getSeoImage } from '_config/next-seo.config';
import { AppProviders } from '_core/app-providers';
import { getUserConsentCookie } from '_core/cookies';
import { isSerializedError, SerializedError, serializeError } from '_core/error/error-serializer';
import { Expertise } from '_core/repos/expertises.repo';
import { getPublicApiRequestLink, publicApiRepo } from '_core/repos/public-api.repo';
import { Reviews } from '_core/repos/public-api.ssr.repo';
import { agenciesRepo } from '_core/repos/public-api/agencies.repo';
import { getCompanyLogos } from '_core/utils/company-logos';
import { baseSsrPrefetchQueries } from '_core/utils/public-api';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { getServerSideTranslations } from '_core/utils/ssr';
import { isPathMatchingMiddleware } from '_core/utils/utils';
import { clientCompaniesData, CompanyType } from '_data/companies';
import { customConfig } from '_features/custom/custom.config';
import {
  AGENCY_CARDS_LAYOUT,
  AGENCY_CARDS_LAYOUT_NORMAL,
  LayoutType,
} from '_features/longtail/components/LayoutSwitch/constants';
import { getGraphqlSortBy, getJsonApiSortBy } from '_features/longtail/components/Sort/utils';
import { DirectoryPage, DirectoryPageData } from '_features/longtail/DirectoryPage';
import { getFiltersForGraphql } from '_features/longtail/sections/Filters/constants';
import { filtersToGraphqlFilters } from '_features/longtail/sections/Filters/utils';
import { filteredLinks, getServiceTopicId } from '_features/longtail/utils';
import {
  Agencies,
  AnchorType,
  CardsSectionOptions,
  Domain,
  Links,
  ORGANIC,
  Page,
  PageContent,
  PAID,
  Works,
  WorksSectionOptions,
} from '_types/public-api';

type Prop = {
  data?: DirectoryPageData | null;
  locale?: string;
  canonical: string;
  query?: string;
  origin?: string;
  userConsent: string | null;
  error: SerializedError | null;
  page?: string;
  sort?: string;
  url?: string;
  publicApiNavBarUrl?: string;
  embed?: boolean;
  agencyCardsLayout?: LayoutType;
  abValue?: string;
  noFiltersResults?: boolean;
  dehydratedState?: DehydratedState;
};

type PageOptions = {
  hidePaidSection: boolean;
  cardsOptions?: CardsSectionOptions;
  worksOptions?: WorksSectionOptions;
};

const prefetchAllQuery = async (
  url: string,
  pageInfo: Page,
  queryParams: string,
  options: PageOptions,
  origin: string,
  locale: string,
  domain: Domain,
) => {
  const { hidePaidSection, cardsOptions, worksOptions } = options;
  const params = new URLSearchParams(queryParams);
  const after = params.get('after');
  const before = params.get('before');
  const page = params.get('page') ?? '1';
  const sort = params.get('sort') ?? 'position';

  const { host, path } = pageInfo.data.attributes;
  const agencyFilters = getFiltersForGraphql(locale, []); // Pass the t function directly from props/context
  let filters = {};

  try {
    filters = JSON.parse(params.get('filters') ?? '{}');
    if (isNonEmptyPlainObject(filters)) {
      const serviceTopicId = getServiceTopicId(pageInfo);
      filters = filtersToGraphqlFilters(
        agencyFilters,
        JSON.parse(params.get('filters') ?? '{}'),
        serviceTopicId as string,
        domain as Domain,
        locale,
      );
    }
  } catch (e) {
    Monitor.captureException(
      `Malformed filters ${JSON.stringify({
        url,
        filters,
      })}, ERROR: ${(e as Error).message}`,
    );
  }
  const agencyQueries = [];

  if (Object.keys(filters).length > 0 || sort !== 'position') {
    // When filtering let's keep only 20 limit
    // Graphql query complexity increases with the number of agencies
    // const { limit } = getOffsetAndLimitByPage(parseInt(page), cardsOptions?.limit);
    const limit = 20;

    agencyQueries.push(
      agenciesRepo.getAgencies(host, path, locale, origin, limit, after, before, getGraphqlSortBy(sort), {
        ...filters,
        sections: [ORGANIC],
      }),
    );
    agencyQueries.push(
      agenciesRepo.getAgencies(host, path, locale, origin, 6, null, null, sort, { ...filters, sections: [PAID] }),
    );
  } else {
    // If someone ask for specific page we return the non filter endpoints until we fix this behaviour
    agencyQueries.push(
      publicApiRepo.getAgencies({
        page: url,
        section: 'organic',
        number: page,
        sort: getJsonApiSortBy(sort), // careful that sort is broken on json api
        cardsOptions,
        locale,
      }),
    );
    agencyQueries.push(
      publicApiRepo.getAgencies({ page: url, section: 'paid', number: '1', sort: 'position', limit: 6, locale }),
    );
  }

  const queries = [
    agencyQueries[0],
    publicApiRepo.getWorks({ page: url, limit: Number(worksOptions?.limit ?? 3) }),
    publicApiRepo.getReviews(),
    getCompanyLogos(clientCompaniesData, 8),
  ];
  const paidAgenicesQuery = !hidePaidSection ? agencyQueries[1] : undefined;
  return await Promise.all([...queries, paidAgenicesQuery]);
};

const Route = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const {
    data,
    error,
    locale = 'en',
    canonical,
    query,
    origin = '',
    sort,
    agencyCardsLayout,
    noFiltersResults,
  } = props;
  const { t } = useTranslation(customConfig.i18nNamespaces);
  const router = useRouter();
  const alternatesLinks = data?.links != null ? filteredLinks(data.links, 'hreflang') : [];

  if (error != null && Boolean(isSerializedError(error))) {
    const { status } = error;
    if (status === 404) return <Public404Page message={t('common:pageErrors.occured')} locale={locale} />;
  }

  if (data != null) {
    const { page: pageInfo } = data;
    const navigationData = {
      origin,
      locale,
      resolvedUrl: router.asPath,
      query,
      languageSwitcthRedirectHome: true,
      agencyCardsLayoutValue: agencyCardsLayout,
      sort,
      expertiseId: data?.page?.data?.attributes?.briefing_options?.expertise?.id,
      noFiltersResults,
      domain: data?.domain,
    };

    const hasPageQuery = query?.includes('page=');

    return (
      <Fragment>
        <PageHead
          showAlternates={true}
          alternateLinks={alternatesLinks}
          canonical={canonical}
          query={query}
          origin={origin}
          locale={locale as string}
        />
        <GenericPageSeo
          title={pageInfo.data.attributes.metadata.title_tag}
          description={pageInfo.data.attributes.metadata.metadescription}
          nextSeoProps={{
            nofollow: pageInfo.data.attributes.indexable !== true,
            noindex: pageInfo.data.attributes.indexable !== true || hasPageQuery,
          }}
          image={getSeoImage('default')}
          canonical={canonical}
        />
        <LayoutPagesRouterLight navigationData={navigationData} data={data}>
          <DirectoryPage data={data} navigationData={navigationData} t={t} />
        </LayoutPagesRouterLight>
      </Fragment>
    );
  }

  return <Public404Page message={t('common:pageErrors.occured')} locale={locale} />;
};

export default function CustomRoute(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <AppProviders dehydratedState={props.dehydratedState}>
      <Route {...props} />
    </AppProviders>
  );
}

const cacheOptions = cacheConfig.pages['longtails'] ?? { httpCacheControl: '' };

export const getServerSideProps: GetServerSideProps<Prop> = async (context) => {
  // Sometimes /next/chunks end here because of browser caching some chunks that are not aknowledged anymore by the server
  // We need to make them 404
  // See more: https://sortlist.sentry.io/issues/6125830352/?environment=production&project=5191789&query=&referrer=issue-stream&statsPeriod=30d&stream_index=0
  if (isPathMatchingMiddleware(context.resolvedUrl, config.matcher[0]) === false) {
    context.res.statusCode = 404;
    return {
      props: {
        error: serializeError(new HttpNotFound({ message: 'Page not found', url: context.resolvedUrl })),
      } as unknown as Prop,
    };
  }
  // IMPORTANT: if you change the defaults for sort and position here please inform #chapter-ai-data
  // so that they can adapt the leadforensic script
  const { resolvedUrl, query, res, req } = context;

  // using resolvedUrl instead of context.query so that the pathname is not included
  // eg. for www.sortlist.fr/s/communication-de-crise/france-fr?page=2
  // query = { page: '2', custom: [ 's', 'communication-de-crise', 'france-fr' ] }

  const paramString = resolvedUrl.split('?')[1];
  const urlSearchParams = new URLSearchParams(paramString);
  const queryParams = urlSearchParams.toString();

  const { sort = 'position', page = '1', filters } = query;
  const origin = req.headers['sortlist-origin'];
  const locale = req.headers['sortlist-locale'];
  const canonical = req.headers['sortlist-canonical'];
  const hidePaidSection = req.headers['sortlist-hide-paid-section'] === 'true';

  const { i18nNamespaces } = customConfig;

  // example of retrieving the user consent that was set by the user in the front-end
  const userConsent = getUserConsentCookie(req);

  let error: SerializedError | null = null;

  assertNonEmptyString(origin, new HttpBadRequest({ message: 'Missing [origin] parameter' }));
  assertNonEmptyString(locale, new HttpBadRequest({ message: 'Missing [locale] parameter' }));
  assertNonEmptyString(canonical, new HttpBadRequest({ message: 'Missing [canonical] parameter' }));
  assertNonEmptyStringOrNull(sort, new HttpBadRequest({ message: '[sort] empty or multiple values found' }));
  assertNonEmptyStringOrNull(page, new HttpBadRequest({ message: '[page] empty or multiple values found' }));

  const embed = resolvedUrl.includes('/embed');
  const url = getPublicApiRequestLink(origin, locale, context.resolvedUrl.replace('/hidden/', '/'));

  const queryClient = new QueryClient();

  let organicAgencies, works, reviews, companies, paidAgencies, links, homepageLinks, expertises, domain;
  let canonicalLink = '';
  let publicApiNavBarUrl = '';
  let hasCanonicalPage = false;
  let agencyCardsLayout = getCookie(AGENCY_CARDS_LAYOUT, { req, res });
  let pageInfo;
  let noFiltersResults = false;
  try {
    pageInfo = await publicApiRepo.getPage({ page: `${url}?include=canonical_page,redirection_page,topics` });
    const { http_status: httpStatus, frontend_type: frontendType } = pageInfo.data.attributes;

    const relationshipsData = pageInfo.data.relationships;
    const hasRedirectPage = relationshipsData.redirection_page.data != null;
    hasCanonicalPage = relationshipsData.canonical_page.data != null;

    const findRelationRedirectPage = pageInfo?.included.find(
      (page) => page.id === relationshipsData?.redirection_page?.data?.id,
    ) as PageContent | undefined;
    const findRelationCanonicalPage = pageInfo?.included.find(
      (page) => page.id === relationshipsData?.canonical_page?.data?.id,
    ) as PageContent | undefined;

    canonicalLink = getLocalizedUrl({
      ...findRelationCanonicalPage?.attributes,
      link: findRelationCanonicalPage?.attributes.path,
    } as AnchorType);

    if (httpStatus != 200 && frontendType === 'directory') {
      if ([301, 302].includes(httpStatus) && pageInfo.included.length > 0) {
        const redirectPage = pageInfo.included[0] as PageContent;
        const redirectDoamin = DomainInfo.getFromOrigin(`https://${redirectPage.attributes.host}`);
        const isMainLocale = redirectDoamin?.getMainLocale() == redirectPage.attributes.locale;
        const localeToRedirectLocale = isMainLocale ? '' : `/${redirectPage.attributes.locale}`;

        return {
          redirect: {
            permanent: httpStatus === 301,
            destination: hasRedirectPage
              ? getLocalizedUrl({
                  ...findRelationRedirectPage?.attributes,
                  link: findRelationRedirectPage?.attributes.path,
                } as AnchorType)
              : `https://${redirectPage.attributes.host}${localeToRedirectLocale}${redirectPage.attributes.path}`,
          },
        };
      } else if ([301, 302].includes(httpStatus) && pageInfo.included.length == 0) {
        throw new HttpInternalServerError({
          message: 'Redirection status found, no page to redirect provided!',
          url,
        });
      } else if ([410].includes(httpStatus)) {
        throw new HttpGoneError({
          message: "This page has been deleted and it's not accessible anymore.",
          url,
        });
      }
    }
    const { page_options } = pageInfo.data.attributes;
    const { cards: cardsOptions, works: worksOptions } = page_options ?? {};

    agencyCardsLayout = cardsOptions?.layout ?? agencyCardsLayout ?? AGENCY_CARDS_LAYOUT_NORMAL;

    const response = await baseSsrPrefetchQueries(origin, locale, context.resolvedUrl, queryClient, true);
    publicApiNavBarUrl = response.publicApiNavBarUrl;
    homepageLinks = response.homeLinks;
    links = response.links;
    expertises = response.expertises;
    domain = response.domain;

    [organicAgencies, works, reviews, companies, paidAgencies] = await prefetchAllQuery(
      url,
      pageInfo,
      queryParams,
      {
        hidePaidSection,
        cardsOptions,
        worksOptions,
      },
      origin,
      locale,
      domain as Domain,
    );

    let filtersObject = {};
    try {
      if (filters != null) {
        filtersObject = JSON.parse(filters as string);
      }
    } catch (e) {
      console.error('Error parsing filters', e);
    }

    const isSeaPage = frontendType === 'google_ads_visibility';
    const hasOrganicResults = isNonEmptyArray((organicAgencies as Agencies)?.data);
    const hasPaidResults = isNonEmptyArray((paidAgencies as Agencies)?.data);
    const hasFiltersApplied = Object.keys(filtersObject).length > 0;

    if (hasFiltersApplied && !hasOrganicResults && !hasPaidResults) {
      paidAgencies = await publicApiRepo.getAgencies({
        page: url,
        section: 'paid',
        number: '1',
        sort: 'position',
        limit: 6,
        locale,
      });
      noFiltersResults = true;
    }

    // return a 404 when there are no organic agencies
    // ie. for wrong page numbers
    if (!isSeaPage && !hasOrganicResults && page !== '1' && !noFiltersResults) {
      throw new HttpNotFound({ message: 'No agencies found', url });
    }
  } catch (e) {
    // Limitation: Typescript exceptions are not typed, so we rely on what is supposed to happen
    if ((e as any).status !== 404) {
      throw e;
    }

    // @TODO: use a more elegant way when upgrade to NextJS 10 https://nextjs.org/blog/next-10#redirect-and-notfound-support-for-getstaticprops--getserversideprops
    context.res.statusCode = 404;
    error = serializeError(e as Error);
  }

  if (!context.res.headersSent) {
    context.res.setHeader('Cache-Control', cacheOptions.httpCacheControl);
  }
  const data =
    pageInfo != null && error == null
      ? {
          page: pageInfo as Page,
          organicAgencies: organicAgencies as Agencies,
          works: works as Works,
          reviews: reviews as Reviews,
          companies: companies as CompanyType[],
          paidAgencies: paidAgencies as Agencies,
          links: links as Links,
          homepageLinks: homepageLinks as Links,
          expertises: expertises as Expertise[],
          domain: domain as Domain,
        }
      : null;

  return {
    props: {
      data,
      locale,
      canonical: hasCanonicalPage ? canonicalLink : canonical,
      query: queryParams,
      origin,
      dehydratedState: dehydrate(queryClient),
      userConsent,
      error,
      page,
      sort,
      url,
      publicApiNavBarUrl,
      embed,
      agencyCardsLayout: (agencyCardsLayout ?? '') as LayoutType,
      noFiltersResults,
      ...(await getServerSideTranslations(locale, i18nNamespaces)),
    },
  };
};
