'use client';

import { ClampLines } from '@sortlist-frontend/design-system/ssr';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import { Fragment } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { TopLinks } from '_components/common/TopLinks';
import { NavigationData } from '_components/layout/Layout';
import { Expertise } from '_core/repos/expertises.repo';
import { CompanyType } from '_data/companies';
import { Hero } from '_features/longtail/sections/Hero/Hero';
import { filteredLinks, getServiceTopicId, SHOW_MODIFIERS } from '_features/longtail/utils';
import { Agencies, Agency, Domain, Links, Page, WorkIncludedAttributes, Works as WorksType } from '_types/public-api';

import { Actions } from './Actions';
import { AgencyCta } from './components/AgencyCta';
import { Breadcrumbs } from './components/Breadcrumbs';
import { Pagination } from './components/Pagination';
import { AgenciesPanel } from './sections/AgenciesPanel/AgenciesPanel';
import { Description } from './sections/Description';
import { Faq } from './sections/Faq';
import { FiltersBar } from './sections/Filters/FiltersBar';
import { WorksSection } from './sections/Works/WorksSection';
import { JsonLd } from './seo/JsonLd';

export type DirectoryPageData = {
  page: Page;
  links: Links;
  homepageLinks: Links;
  paidAgencies: Agencies;
  organicAgencies: Agencies;
  works: WorksType;
  companies: CompanyType[];
  expertises?: Expertise[];
  domain?: Domain;
};

type Props = {
  navigationData: NavigationData;
  data: DirectoryPageData;
  t: TFunction;
  appRouter?: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const DirectoryPage = (props: Props) => {
  const { data, t, navigationData, appRouter = false } = props;
  const { page, links, companies, organicAgencies, paidAgencies, works: ssrWorks, expertises } = data;
  const { origin, locale, resolvedUrl, query, agencyCardsLayoutValue, noFiltersResults } = navigationData;

  const {
    cards: cardsOptions,
    hero: heroOptions,
    works: worksSectionOptions,
  } = page?.data?.attributes?.page_options ?? {};

  const agencies = ssrWorks?.included?.filter((work) => work.type === 'agency') as Agency[];
  const works = ssrWorks?.included?.filter((work) => work.type === 'work') as WorkIncludedAttributes[];

  const frontendType = page?.data?.attributes?.frontend_type;
  const isSeaPage = frontendType === 'google_ads_visibility';

  const domainInfo = DomainInfo.getFromOrigin(origin);

  const breadcrumbs = filteredLinks(links, 'breadcrumbs');
  const otherLinks = filteredLinks(links, 'other');
  const hasPaidAgencies = paidAgencies?.data != null && paidAgencies?.data.length > 0;
  const hasAgencies = organicAgencies?.data != null && organicAgencies?.data.length > 0;
  const showSections = hasAgencies;
  const modifier = SHOW_MODIFIERS ? page?.data?.attributes?.metadata?.modifier : undefined;

  const totalAgenciesCount =
    noFiltersResults === true ? 0 : (organicAgencies?.meta.pagination.records ?? 0) + (paidAgencies?.data.length ?? 0);

  const serviceTopicGraphqlId = getServiceTopicId(page);

  return (
    <Fragment>
      <Actions data={data} navigationData={navigationData} />
      <JsonLd
        data={page}
        breadcrumbs={breadcrumbs}
        otherLinks={otherLinks}
        useAppDirectory={appRouter}
        homePageUrl={domainInfo?.getLocalizedUrl(locale) ?? ''}
        t={t}
      />
      <div>
        {heroOptions?.show === false ? (
          <h1 className="container-lg bold mt-48 mb-24 h3">{page?.data.attributes.title.title}</h1>
        ) : (
          <Hero page={page} t={t} companies={companies} appRouter={appRouter} navigationData={navigationData} />
        )}
      </div>
      <div className="pb-64 pb-xs-48 relative">
        {!isSeaPage ? (
          <Fragment>
            <Breadcrumbs
              className="container-lg py-12"
              breadcrumbs={breadcrumbs ?? []}
              navigationData={navigationData}
              t={t}
              appRouter={appRouter}
              totalAgenciesCount={totalAgenciesCount}
              modifier={modifier}
            />
            <FiltersBar
              agencyCardsLayoutValue={agencyCardsLayoutValue as string}
              navigationData={navigationData}
              serviceTopicGraphqlId={serviceTopicGraphqlId}
            />
          </Fragment>
        ) : null}
        {page.data.attributes.title.excerpt != null ? (
          <div className="container-lg pb-24">
            <ClampLines
              className="small text-secondary-500 lh-2"
              seeMoreLessClassName="text-nowrap small text-secondary-500"
              lines={3}
              text={page.data.attributes.title.excerpt}
              showMoreLessText={true}
              seeLessText={t('common:clampLines.seeLess')}
              seeMoreText={t('common:clampLines.seeMore')}
            />
          </div>
        ) : null}
        {cardsOptions?.show !== false && (hasAgencies || hasPaidAgencies) ? (
          <AgenciesPanel
            appRouter={appRouter}
            t={t}
            page={page}
            navigationData={navigationData}
            organicAgencies={organicAgencies}
            paidAgencies={paidAgencies}
            isSeaPage={isSeaPage}
            expertises={expertises as Expertise[]}
            divider={<h2 className="text-secondary-900 medium mb-16 p">{page?.data.attributes.title.organic_title}</h2>}
            agencyCardsLayout={agencyCardsLayoutValue as string}
          />
        ) : null}
      </div>
      {isSeaPage ? null : (
        <Pagination
          className="mt-16 px-4"
          origin={origin}
          path={`${resolvedUrl ?? ''}?${query}`}
          agencies={organicAgencies}
        />
      )}
      {!isSeaPage ? (
        <Fragment>
          {showSections ? (
            <div className="container-md">
              <div className="pt-64 pb-64 pb-xs-48 relative">
                <AgencyCta t={t} navigationData={navigationData} />
              </div>
            </div>
          ) : null}
          {showSections && <hr className="divider mb-xs-20" />}
          {page?.data.attributes.description != null && (
            <Fragment>
              <div className="container-md py-64">
                <Description description={page?.data.attributes.description} />
              </div>
              <hr className="divider" />
            </Fragment>
          )}
          {works != null && worksSectionOptions?.show !== false && (
            <Fragment>
              <div className="container-lg py-32">
                <WorksSection agencies={agencies} works={works} t={t} modifier={modifier} />
              </div>
              <hr className="divider mt-64" />
            </Fragment>
          )}
          <div className="container-lg px-0 pt-32">
            {<TopLinks linksIdentificationKey="trending_pages" links={links} navigationData={navigationData} />}
            {page?.data.attributes.faq?.items != null && page?.data.attributes.faq?.items?.length > 0 ? (
              <Faq page={page} t={t} />
            ) : null}
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
