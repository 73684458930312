import { cx } from '@emotion/css';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Monitor } from '@sortlist-frontend/mlm';
import { intToString } from '@sortlist-frontend/utils';
import { Fragment } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { Agencies } from '_types/public-api';

import { CurrentPageButtonClassName, PageButtonClassName } from '.';

type Props = {
  className?: string;
  agencies?: Agencies;
  origin: string;
  path: string;
};

export const CursorPagination = (props: Props) => {
  const { className, agencies, origin, path } = props;

  const domainInfo = DomainInfo.getFromOrigin(origin);
  const {
    meta: {
      pagination: { nextPageCursor, previousPageCursor },
    },
  } = agencies ?? { meta: { pagination: { nextPageCursor: null, previousPageCursor: null } } };

  const routeChunks = path.split('?');
  const asPath = routeChunks[0];
  const query = new URLSearchParams(routeChunks[1]);

  const currentPage = Number(query.get('page') ?? '1');
  const hasNextPage = nextPageCursor != null;
  const hasPreviousPage = previousPageCursor != null;

  const setPage = (pageColumn: number, dir: 'next' | 'previous') => {
    if (nextPageCursor == null && previousPageCursor == null) {
      Monitor.captureException('No next or previous page cursor on graphql pagination', {
        extra: {
          nextPageCursor,
          previousPageCursor,
          path,
        },
      });
      return;
    }
    if (dir === 'next' && nextPageCursor != null) {
      query.set('after', nextPageCursor);
      query.delete('before');
    } else if (dir === 'previous' && previousPageCursor != null) {
      query.set('before', previousPageCursor);
      query.delete('after');
    }
    query.set('page', intToString(pageColumn) ?? '1');

    if (pageColumn === 1) {
      query.delete('after');
      query.delete('before');
      query.delete('page');
      const queryParams = Boolean(query.toString()) ? `?${query.toString()}` : '';
      return `${domainInfo?.getOriginUrl()}${asPath}${queryParams}`;
    } else {
      return `${domainInfo?.getOriginUrl()}${asPath}?${query.toString()}`;
    }
  };

  return (
    <div className={cx(className, 'layout-align-center-center layout-row')}>
      {hasPreviousPage ? (
        <Fragment>
          <a className={cx(PageButtonClassName)} href={setPage(currentPage - 1, 'previous')}>
            <ArrowBack style={{ fontSize: 14 }} />
          </a>
          <a className={cx(PageButtonClassName)} href={setPage(currentPage - 1, 'previous')}>
            {currentPage - 1}
          </a>
        </Fragment>
      ) : null}
      <span className={cx(CurrentPageButtonClassName)}>{currentPage}</span>
      {hasNextPage ? (
        <Fragment>
          <a className={cx(PageButtonClassName)} href={setPage(currentPage + 1, 'next')}>
            {currentPage + 1}
          </a>
          <a className={cx(PageButtonClassName)} href={setPage(currentPage + 1, 'next')}>
            <ArrowForward style={{ fontSize: 14 }} />
          </a>
        </Fragment>
      ) : null}
    </div>
  );
};
