'use client';

import { extractAbTestsFromCookiesClient, useExtractFeatureFlagOverrides } from '@sortlist-frontend/feature-flags';
import { AnalyticsProvider, TrackingProvider } from '@sortlist-frontend/tracking';
import { isNonEmptyPlainObject } from '@sortlist-frontend/utils';
import { getCookie } from 'cookies-next';
import dynamic from 'next/dynamic';
import { Fragment, useEffect, useState } from 'react';

import { WorkDetailModalEmbed } from '_components/common/WorkDetailModal/WorkDetailModalEmbed';
import { ComparatorSidebarEmbed } from '_components/Comparator/Menu/ComparatorSidebarEmbed';
import { NavigationData } from '_components/layout/Layout';
import { PublicAppContextProvider } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';
import { useTrackWithoutContext } from '_core/repos/track.repo';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';
import { Agency, WorkIncludedAttributes } from '_types/public-api';

import { SnackbarProviderLight } from './components/SnackbarProviderLight';
import { DirectoryPageData } from './DirectoryPage';
import { PAID_AGENCIES_SECTION_LIMIT } from './sections';
import { ClientActions } from './sections/AgenciesPanel/AgencyCard/components/ClientActions';
import { SHOW_MODIFIERS } from './utils';

const BriefingDialog = dynamic(
  () => import('_components/Briefing/versions/NormalBriefing/BriefingDialog').then((mod) => mod.BriefingDialog),
  {
    ssr: false,
  },
);

type Props = {
  data: DirectoryPageData;
  navigationData: NavigationData;
};

const TrackAgencies = (props: Props & { runningExperiments?: Record<string, unknown> }) => {
  const { data } = props;
  const { paidAgencies, organicAgencies, page } = data;
  const { trackUntyped, ready } = useTracker();

  useEffect(() => {
    if (ready === true && page != null) {
      const { absoluteEntryUrl, absoluteEntryDate, sessionEntryUrl } = getSourceObject();

      trackUntyped('agencyLongtailsLoaded', {
        locale: page?.data.attributes.locale,
        expertise: page?.data.attributes.briefing_options?.expertise?.name,
        location: page?.data.attributes.briefing_options?.location?.name,
        faq: page?.data.attributes.faq != null,
        description: page?.data.attributes.description != null,
        absoluteEntryUrl,
        absoluteEntryDate,
        sessionEntryUrl,
      });
    }
  }, [ready, page]);

  return (
    <Fragment>
      {paidAgencies?.data.slice(0, PAID_AGENCIES_SECTION_LIMIT)?.map((agency, keyInArray) => {
        const includedAgency = paidAgencies.included != null ? paidAgencies.included[keyInArray] : null;
        if (includedAgency != null) {
          return (
            <ClientActions
              key={agency.id}
              page={page}
              agency={includedAgency}
              data={agency}
              keyInArray={keyInArray}
              paid
              navigationData={props.navigationData}
            />
          );
        }
        return null;
      })}

      {organicAgencies?.data?.map((agency, keyInArray) => {
        const includedAgency = organicAgencies.included != null ? organicAgencies.included[keyInArray] : null;
        if (includedAgency != null) {
          return (
            <ClientActions
              key={agency.id}
              page={page}
              agency={includedAgency}
              data={agency}
              keyInArray={keyInArray}
              navigationData={props.navigationData}
            />
          );
        }
        return null;
      })}
    </Fragment>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Actions = (props: Props) => {
  const { navigationData, data } = props;
  const { origin, resolvedUrl, locale } = navigationData;
  const [active, setActive] = useState(false);

  const { page, works: ssrWorks } = data;

  const worksAgencies = ssrWorks?.included?.filter((work) => work.type === 'agency') as Agency[] | undefined;
  const works = ssrWorks?.included?.filter((work) => work.type === 'work') as WorkIncludedAttributes[] | undefined;

  const featureFlagsConfig = useExtractFeatureFlagOverrides({}, `${origin}${resolvedUrl}`);
  const runningExperiments = extractAbTestsFromCookiesClient(featureFlagsConfig);
  const experimentsRunning = runningExperiments.gbuuid != null && isNonEmptyPlainObject(runningExperiments.experiments);
  const { mutateAsync: internalTrack } = useTrackWithoutContext(origin, `${origin}${resolvedUrl}`);

  const gbuuid = getCookie('gbuuid')?.toString();
  const anonymousUserId = getCookie('ajs_anonymous_id')?.toString();

  useEffect(() => {
    internalTrack({
      name: 'longtailLoaded',
      url: location.href,
    });

    const container = document.getElementById('main-ctn');

    const handleActive = () => {
      if (!active) {
        setActive(true);
        window?.removeEventListener('mousemove', handleActive);
        window?.removeEventListener('click', handleActive);
        window?.removeEventListener('touchstart', handleActive);
        container?.removeEventListener('scroll', handleActive);
      }
    };

    window?.addEventListener('mousemove', handleActive);
    window?.addEventListener('click', handleActive);
    window?.addEventListener('touchstart', handleActive);
    container?.addEventListener('scroll', handleActive);

    return () => {
      window?.removeEventListener('mousemove', handleActive);
      window?.removeEventListener('click', handleActive);
      window?.removeEventListener('touchstart', handleActive);
      container?.removeEventListener('scroll', handleActive);
    };
  }, []);

  const { briefing_options, frontend_type } = { ...page?.data?.attributes };
  const { expertise } = { ...briefing_options };
  const expertiseId = Number(expertise?.id);

  const modifiers = SHOW_MODIFIERS
    ? {
        matching: briefing_options?.modifier_matching,
        rightProvider: briefing_options?.modifier_right_provider,
      }
    : undefined;

  return (
    <Fragment>
      <PublicAppContextProvider
        canonical={`${origin}${resolvedUrl}`}
        origin={origin}
        locale={locale}
        briefingOptions={{
          page: frontend_type,
          address: briefing_options?.location?.address,
          placeId: briefing_options?.location?.place_id,
          expertise: expertiseId,
          expertiseObject: expertise != null ? { expertiseId, ...expertise } : undefined,
          skills: briefing_options?.skills,
          skillObjects: page.data.attributes.briefing_options?.skill_objects,
          modifiers,
        }}>
        <BriefingDialog />
        <ComparatorSidebarEmbed />
        <WorkDetailModalEmbed works={works} agencies={worksAgencies} />
      </PublicAppContextProvider>
      {active ? (
        <Fragment>
          <AnalyticsProvider>
            <TrackingProvider
              anonymousId={gbuuid ?? anonymousUserId}
              app="appPublic"
              name={page?.data.attributes.frontend_type}
              category={page?.data.attributes.frontend_type}
              properties={{
                experiments: experimentsRunning ? runningExperiments : undefined,
              }}>
              <TrackAgencies {...props} runningExperiments={experimentsRunning ? runningExperiments : undefined} />
            </TrackingProvider>
          </AnalyticsProvider>
          <SnackbarProviderLight />
        </Fragment>
      ) : null}
    </Fragment>
  );
};
