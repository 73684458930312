'use client';

import { Tooltip } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import Image from 'next/image';

import { useWorkMessage } from '_components/common/WorkDetailModal/WorkDetailModalEmbed';
import { useTracker } from '_core/hooks/use-tracker';
import { onImageError } from '_core/media/get-media.utils';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';
import { Agency, WorkIncludedAttributes } from '_types/public-api';

type WorkCardWithModalProps = {
  work: WorkIncludedAttributes;
  agency: Agency;
  works: WorkIncludedAttributes[];
  currentIndex: number;
  disableCache: boolean;
};

const { absoluteEntryUrl, absoluteEntryDate, sessionEntryUrl } = getSourceObject();

export const WorkCardWithModal = (props: WorkCardWithModalProps) => {
  const { work, currentIndex, agency } = props;
  const { trackUntyped } = useTracker();

  const { sendMessage } = useWorkMessage();

  const onClick = () => {
    trackUntyped('workInspected', {
      agencyName: agency.attributes.name,
      agencyPlan: agency.attributes.visibility_plan,
      agencySlug: agency.attributes.slug,
      item: work.attributes.name,
      work_slug: work.attributes.slug,
      absoluteEntryUrl,
      absoluteEntryDate,
      sessionEntryUrl,
    });
    trackUntyped('workImpression', {
      workSlug: work.attributes.slug,
      agencySlug: agency.attributes.slug,
      type: 'longtail-modal',
      absoluteEntryUrl,
      absoluteEntryDate,
      sessionEntryUrl,
    });
    sendMessage('open-work-dialog', {
      workSlug: work.attributes.slug,
      agencyId: agency.id,
      currentIndex,
    });
  };

  const IMAGE_WIDTH = 320;

  return (
    // TODO: Remove this and use the component used on the profile. This is unecessary code duplication leading to problems (layout issues for instance) that we don't see right away and need to fix afterwards.
    <div className="p-8">
      <div className="layout-column pb-8" style={{ maxWidth: IMAGE_WIDTH }}>
        <div className="relative bg-secondary-100 work-card-thumbnail">
          <Image
            src={ImageSourceHelper.fromUrl(
              work.attributes.illustration_url || '/_img/default/default-image-landscape.svg',
              {
                width: 960,
              },
            )}
            loading={'lazy'}
            alt={work.attributes.name}
            onError={onImageError}
            className="cursor-pointer rounded-md shadow-2"
            onClick={onClick}
            width={IMAGE_WIDTH}
            height={225}
            role="presentation"
          />
        </div>
        <Tooltip placement="top" title={work.attributes.name}>
          <p className="bold text-truncate cursor-pointer py-16 pl-8 mb-4">{work.attributes.name}</p>
        </Tooltip>
      </div>
    </div>
  );
};
