import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { Fragment } from 'react';

import { BriefingEmbedButton } from '_components/Briefing/components/BriefingEmbedButton';
import { ComparatorButtonEmbed } from '_components/Comparator/Menu/ComparatorButtonEmbed';
import { SearchTopics } from '_components/SearchTopic/SearchTopics';
import { customConfig } from '_features/custom/custom.config';
import { DirectoryPageData } from '_features/longtail/DirectoryPage';

import { Layout, LayoutProps, NavigationData } from './Layout';
import { SubNav } from './SubNav';

type Props = {
  navigationData: NavigationData;
  data: DirectoryPageData;
};

export const LayoutPagesRouterLight = (props: LayoutProps & Props) => {
  const { navigationData, data, children, ...restProps } = props;

  const { t } = useTranslation(customConfig.i18nNamespaces);

  const { expertises, homepageLinks, page } = data;

  const expertiseId = page?.data?.attributes?.briefing_options?.expertise?.id;

  return (
    <Layout
      links={homepageLinks}
      expertises={expertises}
      navigationData={navigationData}
      showRankUpdated
      t={t}
      briefingCTA={
        <BriefingEmbedButton
          data-testid="briefing-button-navbar-project"
          size="sm"
          buttonStyle="primary"
          buttonVariant="raised"
          className="mx-8"
          label={t('common:navbar.menu.postAProject')}
          truncate={false}
          cta="navbar-need"
          expertiseId={expertiseId}
        />
      }
      briefingAdviceCTA={
        <BriefingEmbedButton
          cta="navbar-advice"
          data-testid="navbar-advice-cta"
          label={
            <Trans i18nKey={'common:navbar.findAgencies.getAdvice.buttonLabel'} t={t} appRouter>
              Get advice <span className="italic">it’s free!</span>.
            </Trans>
          }
          buttonStyle={'primary'}
          buttonVariant={'raised'}
          size="sm"
        />
      }
      comparatorCTA={<ComparatorButtonEmbed title={t('common:comparator.myShortlist')} />}
      search={
        <SearchTopics
          type="service"
          cta="hero"
          page="search-landing"
          element="search-landing"
          placeholder={t('common:navbar.menu.whatService')}
          navigationData={navigationData}
        />
      }
      searchMobile={
        <SearchTopics
          type="service"
          cta="hero"
          page="search-landing"
          element="search-landing"
          placeholder={t('common:navbar.menu.whatService')}
          navigationData={navigationData}
          mobile
        />
      }
      {...restProps}>
      <Fragment>
        <SubNav otherString={t('home:sections.catalog.others_v1')} links={homepageLinks} />
        {children}
      </Fragment>
    </Layout>
  );
};
