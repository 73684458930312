'use client';

import { cx } from '@emotion/css';
import { Fragment, useMemo, useState } from 'react';

import { NavigationData } from '_components/layout/Layout';
import { categoryFiltered } from '_core/utils/public-links/category-filtered';
import { createLevels } from '_core/utils/public-links/createLevels';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { services } from '_core/utils/public-links/services';
import { sortLevels } from '_core/utils/public-links/sort-levels';
import { Links } from '_types/public-api';

import { LinkList } from './components/LinkList';
import { createSortedLvlAfterPosition } from './utils';

type TopLinksProps = {
  linksIdentificationKey: string;
  seeMore?: string;
  links?: Links;
  navigationData: NavigationData;
};

export const TopLinks = (props: TopLinksProps) => {
  const { linksIdentificationKey, seeMore, links, navigationData } = props;
  const [isActiveIndex, setIsActiveIndex] = useState(0);

  const { origin, locale } = navigationData;
  const allLinks = useMemo(
    () => createLevels(links, services(links, linksIdentificationKey, categoryFiltered), sortLevels),
    [links],
  );

  const sortedLinks = allLinks != null ? createSortedLvlAfterPosition(allLinks) : null;
  const firstLink = useMemo(() => (sortedLinks != null ? Object.keys(sortedLinks[0])[0] : ''), [sortedLinks]);

  const [selectedLink, setSelectedLink] = useState(firstLink);

  if (allLinks == null) return null;

  return (
    <section className="py-64 container-lg px-0">
      <div className="layout-column layout-gt-sm-row layout-align-start-stretch">
        <div
          className={cx(
            {
              'overflow-y-scroll': false, // !media.gtSm
            },
            'top-links-wrapper flex-100 flex-gt-sm-33 layout-row layout-gt-sm-column layout-align-start-start mb-24 mb-gt-sm-0 px-16',
          )}>
          {sortedLinks
            ?.sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
            .map((levelTwo, index) => {
              const link = Object.keys(levelTwo)[0];
              return (
                <button
                  key={`${link}-${index}`}
                  className={cx(
                    { 'ml-16 ml-gt-sm-0': index > 0 },
                    // media.gtSm
                    true ? 'text-break-word' : 'flex-none',
                    isActiveIndex === index ? 'top-links-button-active' : '',
                    'cursor-pointer text-left bold p-0 top-links-button',
                  )}
                  data-testid={`skill-category-${index}`}
                  onClick={() => {
                    setIsActiveIndex(index);
                    setSelectedLink(link);
                  }}>
                  {link}
                </button>
              );
            })}
        </div>
        <div className="layout-column layout-gt-xs-row flex layout-align-start-stretch flex-xs-66">
          {/** Hidden links for the google bot (only links not selected) */}
          {Object.keys(allLinks).map((link, key) => {
            const currentLinks = allLinks[link]['undefined']
              ? allLinks[link]['undefined']['undefined']
              : allLinks[link]['null']['null'];
            if (link !== selectedLink) {
              return (
                <Fragment key={`link-${key}-hidden`}>
                  {currentLinks
                    .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
                    .map((val, key) => {
                      const localizedLink = getLocalizedUrl(val);
                      return (
                        <a
                          href={`${localizedLink}`}
                          key={`${val.anchor}-link-${key}-hidden`}
                          className="text-secondary-900 small bold hide top-links-anchor">
                          {val.anchor}
                        </a>
                      );
                    })}
                </Fragment>
              );
            }
          })}
          <LinkList seeMore={seeMore} selectedLink={selectedLink} allLinks={allLinks} origin={origin} locale={locale} />
        </div>
      </div>
    </section>
  );
};
