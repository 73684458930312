import { TFunction } from '@sortlist-frontend/translation/server-only';

import { Agency, WorkIncludedAttributes } from '_types/public-api';

import { WorkCardWithModal } from './WorkCard';

type WorkProps = {
  works: WorkIncludedAttributes[];
  agencies: Agency[];
  t: TFunction;
  modifier?: string;
};

export const WorksSection = (props: WorkProps) => {
  const { works, agencies, t, modifier } = props;

  const getSubtitle = () => {
    if (modifier == null) return t('longtail:works.subtitle');
    return t('longtail:works.subtitleWithModifier', { agenciesModifier: modifier });
  };

  return (
    <div className="pt-64">
      <h2 className="bold text-center pb-8 serif">{t('longtail:works.title')}</h2>
      <p className="text-center pb-40">{getSubtitle()}</p>

      <div className="layout-row layout-wrap gap-x-16 layout-align-center-center">
        {works?.map((work, index) => {
          const relatedAgency = agencies?.filter((agency) => agency.id === work.relationships.agency.data.id)[0];
          return (
            <WorkCardWithModal
              key={work.attributes.slug}
              work={work}
              works={works}
              agency={relatedAgency}
              currentIndex={index}
              disableCache={false}
            />
          );
        })}
      </div>
    </div>
  );
};
