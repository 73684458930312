import { cx } from '@emotion/css';
import { ArrowBack, ArrowForward, FirstPage, LastPage } from '@mui/icons-material';
import { intToString } from '@sortlist-frontend/utils';

import { DomainInfo } from '_backend/integration/domain-info';
import { Agencies } from '_types/public-api';

import { CursorPagination } from './CursorPagination';
import { AGENCIES_FROM_PAGE_TWO_ON, AGENCIES_ON_FIRST_PAGE } from './utils';

export const PageButtonClassName =
  'rounded layout-row layout-align-center-center small mr-8 text-secondary-500 bg-secondary-200 page-button';
export const CurrentPageButtonClassName =
  'layout-row layout-align-center-center small mr-8 text-neutral-100 bg-secondary-900 bold rounded page-button';

type Props = {
  className?: string;
  agencies?: Agencies;
  origin: string;
  path: string;
};

export const Pagination = (props: Props) => {
  const { className, agencies, origin, path } = props;

  const domainInfo = DomainInfo.getFromOrigin(origin);
  const {
    meta: {
      pagination: { records, current: page, nextPageCursor, previousPageCursor },
    },
  } = agencies ?? { meta: { pagination: { records: 0, current: 0, nextPageCursor: null, previousPageCursor: null } } };

  if (nextPageCursor != null || previousPageCursor != null) {
    return <CursorPagination {...props} />;
  }

  const currentPage = page + 1;
  const pagesCount =
    records > AGENCIES_ON_FIRST_PAGE
      ? Math.ceil((records - AGENCIES_ON_FIRST_PAGE) / AGENCIES_FROM_PAGE_TWO_ON + 1)
      : 1;

  const setPage = (pageColumn: number) => {
    const routeChunks = path.split('?');
    const asPath = routeChunks[0];

    const query = new URLSearchParams(routeChunks[1]);
    query.set('page', intToString(pageColumn) ?? '1');

    if (pageColumn === 1) {
      query.delete('page');
      const queryParams = Boolean(query.toString()) ? `?${query.toString()}` : '';
      return `${domainInfo?.getOriginUrl()}${asPath}${queryParams}`;
    } else {
      return `${domainInfo?.getOriginUrl()}${asPath}?${query.toString()}`;
    }
  };

  if (pagesCount === 1) return null;

  return (
    <div className={cx(className, 'layout-align-center-center layout-row')}>
      {currentPage > 3 ? (
        <a className={cx(PageButtonClassName)} href={setPage(1)}>
          <FirstPage style={{ fontSize: 14 }} />
        </a>
      ) : null}
      {currentPage > 2 ? (
        <a className={cx(PageButtonClassName)} href={setPage(currentPage - 1)}>
          <ArrowBack style={{ fontSize: 14 }} />
        </a>
      ) : null}
      {currentPage > 2 ? (
        <a className={cx(PageButtonClassName)} href={setPage(currentPage - 2)}>
          {currentPage - 2}
        </a>
      ) : null}
      {currentPage > 1 ? (
        <a className={cx(PageButtonClassName)} href={setPage(currentPage - 1)}>
          {currentPage - 1}
        </a>
      ) : null}
      <span className={cx(CurrentPageButtonClassName)}>{currentPage}</span>
      {currentPage + 1 <= pagesCount ? (
        <a className={cx(PageButtonClassName)} href={setPage(currentPage + 1)}>
          {currentPage + 1}
        </a>
      ) : null}
      {currentPage + 2 <= pagesCount ? (
        <a className={cx(PageButtonClassName)} href={setPage(currentPage + 2)}>
          {currentPage + 2}
        </a>
      ) : null}
      {currentPage < pagesCount - 1 ? (
        <a className={cx(PageButtonClassName)} href={setPage(currentPage + 1)}>
          <ArrowForward style={{ fontSize: 14 }} />
        </a>
      ) : null}
      {currentPage < pagesCount - 2 ? (
        <a className={cx(PageButtonClassName)} href={setPage(pagesCount)}>
          <LastPage style={{ fontSize: 14 }} />
        </a>
      ) : null}
    </div>
  );
};
