import { TFunction } from '@sortlist-frontend/translation/server-only';

import { NavigationData } from '_components/layout/Layout';

import { AgencyCtaBriefingCta } from './AgencyCtaBriefingCta';

type Props = {
  t: TFunction;
  navigationData: NavigationData;
};

export const AgencyCta = (props: Props) => {
  const { t, navigationData } = props;

  return (
    <div className="agency-cta bg-neutral-100 p-32 border rounded-xl layout-column layout-align-center-center border-primary-500 shadow-2">
      <div className="layout-column text-center layout-align-center-center">
        <p className="bold h3">{t('longtail:cantFindCta.title')}</p>
        <p className="mt-16">{t('longtail:cantFindCta.subtitle_v1')}</p>
      </div>
      <AgencyCtaBriefingCta label={t('longtail:cantFindCta.cta')} navigationData={navigationData} />
    </div>
  );
};
