'use client';

import { FavoriteTwoTone } from '@mui/icons-material';
import { Badge as MuiBadge } from '@mui/material';
import { Tooltip } from '@sortlist-frontend/design-system/ssr';

import { useComparedAgencies } from '_core/hooks/use-compared-agencies';

import { useComparatorMessage } from './ComparatorSidebarEmbed';

type Props = {
  title: string;
};

export const ComparatorButtonEmbed = (props: Props) => {
  const { title } = props;
  const { comparedAgencies } = useComparedAgencies();

  const { sendMessage } = useComparatorMessage();

  return (
    <MuiBadge badgeContent={comparedAgencies?.length} color="error" max={9}>
      <Tooltip title={title} placement="bottom" arrow={true}>
        <button
          data-testid="navbar-shortlist-button"
          className="p-0 border-none bg-neutral-100 layout-column layout-align-center-center"
          onClick={() => {
            sendMessage('open-comparator');
          }}
          id="navbar-shortlist-btn">
          <FavoriteTwoTone className={comparedAgencies?.length > 0 ? 'text-danger-500' : 'text-secondary-500'} />
        </button>
      </Tooltip>
    </MuiBadge>
  );
};
